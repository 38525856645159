import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dataServices from "../../apiServices/data.services";
import Loader from "../../components/Loader/Loader";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Can } from "../../context/AuthProvider";
import { handleValidationError } from "../../components/CommonFunctions";
import { useAlert } from "react-alert";
import PDFViewerComponent from "../../components/PDFViewer/PdfViewer";
import Html from "react-pdf-html";
import { saveAs } from "file-saver";
import { Document, Page, pdf, StyleSheet } from "@react-pdf/renderer";

const Bill = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    setLoading(true);
    dataServices
      .getInvoiceDetails(id)
      .then((res) => {
        if (res.status === 200) {
          setInvoice(res.data);
        }
      })
      .catch((err) => {
        alert.error(handleValidationError(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const printDocument = async () => {
    const input = document.getElementById("divToPrint");

    // Get the inner HTML of the element as a string
    const htmlString = input.innerHTML;

    // Create a PDF document using @react-pdf/renderer and react-pdf-html
    const MyPDF = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <Html>{htmlString}</Html>
        </Page>
      </Document>
    );

    // Convert the document to a blob and trigger the download
    const pdfBlob = await pdf(<MyPDF />).toBlob();
    saveAs(pdfBlob, "download.pdf");
  };

  const styles = StyleSheet.create({
    page: {
      padding: 10,
    },
  });

  return (
    <div className="content-wrapper">
      <Can I="read" an="Invoice">
        <div className="mt-4">
          <Card className="rounded-4 px-3 pt-3 overflow-hidden mb-3">
            <Card.Body>
              <Card.Title as="h6" className="pb-2">
                Invoice Info
              </Card.Title>
              {invoice ? (
                <>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <span className="text-muted small">Patient Name</span>
                        <br />
                        {invoice.patient.name}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <span className="text-muted small">Date</span>
                        <br />
                        {invoice.payment_date}
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <span className="text-muted small">Invoice No.</span>
                        <br />
                        {invoice.id}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <span className="text-muted small">Payment Method</span>
                        <br />
                        {invoice.paid_by}
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <span className="text-muted small">
                          Transaction ID or cheque number
                        </span>
                        <br />
                        {invoice.transaction_number}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <span className="text-muted small">Amount</span>
                        <br />
                        {invoice.amount}
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col className="text-end">
                      <Button
                        variant="primary"
                        onClick={() =>
                          showInvoice ? printDocument() : setShowInvoice(true)
                        }
                        size="sm"
                      >
                        {showInvoice ? "Print" : "Print preview"}
                      </Button>
                      {showInvoice && (
                        <Button
                          variant="danger"
                          className="ms-2"
                          onClick={() => setShowInvoice(false)}
                          size="sm"
                        >
                          Close
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {/* <Can I="edit" an="Invoice">
                    <a
                      href={`/edit-invoice/${invoice.id}`}
                      className="link-dark"
                    >
                      <div className="edit-profile-icon bg-primary text-white">
                        <span className="material-symbols-outlined h2 m-0">
                          edit
                        </span>
                      </div>
                    </a>
                  </Can> */}
                </>
              ) : (
                loading && <Loader />
              )}
            </Card.Body>
          </Card>
        </div>
      </Can>
      {showInvoice && <PDFViewerComponent invoice={invoice} />}
    </div>
  );
};

export default Bill;
