import React, { useEffect, useState } from "react";
import Html from "react-pdf-html";
import { saveAs } from "file-saver";
import { Document, Page, pdf, StyleSheet } from "@react-pdf/renderer";
import tokenService from "../../apiServices/token.service";

const PDFViewerComponent = ({ invoice,  }) => {
  const [clinicData, setClinicData] = useState();
  useEffect(() => {
    const { clinic } = tokenService.getUser();
    setClinicData(clinic);
  }, []);

  const printDocument = async () => {
    const input = document.getElementById("divToPrint");

    // Get the inner HTML of the element as a string
    const htmlString = input.innerHTML;

    // Create a PDF document using @react-pdf/renderer and react-pdf-html
    const MyPDF = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <Html>{htmlString}</Html>
        </Page>
      </Document>
    );

    // Convert the document to a blob and trigger the download
    const pdfBlob = await pdf(<MyPDF />).toBlob();
    saveAs(pdfBlob, "download.pdf");
  };

  const styles = StyleSheet.create({
    page: {
      padding: 10,
    },
  });

  return (
    <>
      {clinicData && invoice ? (
        <div className="App">
          {/* <h4>Invoice Generator</h4> */}
          {/* <div className="mb5">
            <button onClick={printDocument}>Print</button>
          </div> */}
          <div
            id="divToPrint"
            style={{ backgroundColor: "white", padding: "16px" }}
          >
            <div
              style={{
                maxWidth: "800px",
                margin: "50px auto",
                padding: "20px",
                fontSize: "12px",
                backgroundColor: "#ffffff",
                border: "1px solid #ddd",
              }}
            >
              <h4
                style={{ textAlign: "center", color: "#333", fontSize: "16px" }}
              >
                Invoice
              </h4>

              <table style={{ width: "100%", marginBottom: "20px" }}>
                <tr>
                  <td style={{ fontSize: "12px" }}>
                    <img
                      src={`/img/clinic-logo/${clinicData.logo_url}`}
                      alt="Clinic Logo"
                      style={{
                        width: "150px",
                        marginBottom: "20px",
                      }}
                    />
                    <br />
                    <h3 style={{ margin: 0, color: "#333", fontSize: "14px" }}>
                      {clinicData.name}
                    </h3>
                    <p style={{ margin: "5px 0", fontSize: "12px" }}>
                      Registration Number: {clinicData.number}
                    </p>
                    <p style={{ margin: "5px 0", fontSize: "12px" }}>
                      GST Number: {clinicData.gst_number}
                    </p>
                    <p style={{ margin: "5px 0", fontSize: "12px" }}>
                      {clinicData.address.line1}
                    </p>
                    <p style={{ margin: "5px 0", fontSize: "12px" }}>
                      {clinicData.address.city}, {clinicData.address.state}-
                      {clinicData.address.zipcode} (India)
                    </p>
                  </td>

                  <td style={{ textAlign: "right", fontSize: "12px" }}>
                    <h3 style={{ margin: 0, color: "#333", fontSize: "12px" }}>
                      {invoice.patient.name}
                    </h3>
                    <p style={{ margin: "5px 0", fontSize: "12px" }}>
                      Invoice: {invoice.id}
                    </p>
                    <p style={{ margin: "5px 0", fontSize: "12px" }}>
                      {invoice.payment_date}
                    </p>
                  </td>
                </tr>
              </table>

              <table style={{ borderCollapse: "collapse", fontSize: "12px" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        backgroundColor: "#f2f2f2",
                        textAlign: "left",
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        backgroundColor: "#f2f2f2",
                        textAlign: "right",
                        width: "100px",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      {invoice.description}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                        width: "100px",
                      }}
                    >
                      Rs {invoice.amount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      <strong>Total</strong>
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                        width: "100px",
                      }}
                    >
                      <strong>Rs {invoice.amount} </strong>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                Thank you for your business!
              </p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PDFViewerComponent;
